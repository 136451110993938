import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../ngrx/app.reducers";
import * as fromPeopleAppraisal from "../peopleAppraisal/ngrx/peopleAppraisal.reducers";
import * as PeopleAppraisalActions from "../peopleAppraisal/ngrx/peopleAppraisal.actions";
import { JwtPayload, SenecaResponse, RequiredAuth } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { PeopleAppraisalService } from '../shared/services/peopleAppraisal.service';
import { Router } from '@angular/router';
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import moment from 'moment';
import * as CoreActions from "../core/ngrx/core.actions";
import * as AuthActions from "../auth/ngrx/auth.actions";
import { environment } from 'src/environments/environment';
import { AlloyPerformanceCppServiceResponses, AlloyPerformanceUpwardFeedbackServiceResponses, GetPerformanceAssessmentInfoForManager, GetPerformanceAssessmentInfoForUser, SolOpenAPI } from 'atfcore-commonclasses';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  // Accesso alla scheda di sviluppo
  canAccessPersonalDevelopment: boolean = environment.canAccessPersonalDevelopment;
  // Accesso alla dashboard
  canAccessTheDashboard: boolean = environment.canAccessTheDashboard;
  // Accesso allo smart Feedback
  canAccessToSmartFeedback: boolean = environment.canAccessToSmartFeedback;
  // Accesso allo peer Feedback
  canAccessToPeerFeedback: boolean = environment.canAccessToPeerFeedback;
  // Accesso allo Feedback 360
  canAccessToFeedback360: boolean = environment.canAccessToFeedback360;
  // Accesso agli appunti privati
  canAccessToPrivateNotes: boolean = environment.canAccessToPrivateNotes;

  runningYear$: Subscription = new Subscription();
  runningYear: any = {};
  phaseName: string = '';
  bannerImage: string = '';


  combinedSelected$: Subscription = new Subscription();

  loggedUser: any;
  runningPhaseDate: any;
  userAcknowledges: any;

  intro!: GuidedTour;
  steps?: TourStep[];

  isUpdatingAcks: boolean = false;
  isImpersonate: boolean = false;
  updateUserAck$: Subscription = new Subscription();

  upwardFeedbackData?: any;
  isLoadingUpwardFeedback: boolean = false;
  selectedFeedbackManager: any;
  upwardFeedbackManagers: any;
  upwardFeedbackDataSelf?: AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForUser;

  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];

  isPeopleAppraisal: boolean = false;
  isSelfAssessment: boolean = false;

  pasInfo$: Subscription = new Subscription();
  isLoadingPasInfo: boolean = false;
  isLoadingPasInfoUser: boolean = false;
  pasData!: {
    perfYear: number;
    perfYearCode: string;
    isNewPas: boolean,
    endDate: string,
    showFeedback?: boolean,
    showFeedback360?: boolean
  };

  pasDataSelf!: {
    perfYear: number;
    perfYearCode: string;
    isNewPas: boolean,
    endDate: string,
    showFeedback?: boolean,
    showFeedback360?: boolean,
    feedback360NewBadge?: boolean
    showFeedback360Suggestions?: boolean
  };

  canAccessCpp: boolean = false;
  canAccessFeedback: boolean = false;
  cppInfo?: any;
  cppInfoForUser?: any;
  isLoadingCppInfo: boolean = false;
  selectedUserList: any[] = [];
  isDownloadingCppReport: boolean = false;
  completedCollaboratorsList: any[] = [];
  searchUserText: string = '';
  isBetweenDatesCpp: boolean = false;
  isBetweenDatesCppForUser: boolean = false;
  today = new Date();
  applicationLang$: Subscription = new Subscription();
  applicationLang: any;
  loadingAllData: boolean = true;

  // Dati utenti feedback 360
  feedbackRunningRound: SolOpenAPI.PerfAlloyFeedback360Round | null = null;
  isLoadingFeedbackSubordinates: boolean = false;
  feedbackSubordinates: any;
  feedbackSubordinates$: Subscription = new Subscription();
  feedbackRunningRound$: Subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private peopleAppraisalStore: Store<fromPeopleAppraisal.PeopleAppraisalState>,
    public translate: TranslateService,
    public router: Router,
    public modalService: ModalService,
    public peopleAppraisalService: PeopleAppraisalService,
    private selfAssessmentService: SelfAssessmentService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private analyticsService: AnalyticsService,
    private guidedTour: GuidedTourService) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics

    // Recupero il round in corso del feedback 360
    this.feedbackRunningRound$ = this.store.select(fromApp.getFeedbackRunningRound).subscribe((runningRound) => {
      if (runningRound) {
        this.feedbackRunningRound = runningRound;
      }
    })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear && this.runningYear.yearCode) {
        this.phaseName = this.runningYear.yearCode;
      }

      const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
      const userAck$: Observable<any> = this.store.select(fromApp.getUserAcknowledges);
      const isSelfAssessment$: Observable<boolean> = this.store.select(fromApp.getIsSelfAssessment);
      const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
      const getRunningPhase$: Observable<any> = this.store.select(fromApp.getRunningPhase);
      this.combinedSelected$ = combineLatest([loggedUser$, userAck$, getRunningPhase$, isSelfAssessment$, isPeopleAppraisal$])
        .subscribe(
          ([loggedUser, userAck, runningPhase, isSelfAssessment, isPeopleAppraisal]) => {
            this.loadingAllData = true;
            this.isSelfAssessment = isSelfAssessment;
            this.isPeopleAppraisal = isPeopleAppraisal;

            if (loggedUser?.auths && loggedUser.auths.find((x: any) => x == RequiredAuth.PERFORMANCEMANAGEMENTALLOY_ACCESS)) {
              this.canAccessCpp = true;
            }
            if (loggedUser?.auths && loggedUser.profiles.find((x: any) => x == 'PERFORMANCEMANAGEMENTALLOY_COMMON')) {
              this.canAccessFeedback = true;
            }

            if (runningPhase && loggedUser && loggedUser.user && this.runningYear && this.runningYear.yearCode) {
              this.loggedUser = loggedUser && loggedUser.user;
              this.runningPhaseDate = runningPhase && runningPhase.peopleAppraisalEndDate;
              this.userAcknowledges = userAck;
              this.bannerImage = this.getBannerImage(this.phaseName.toUpperCase());
              if (isPeopleAppraisal) {
                this.peopleAppraisalStore.dispatch(PeopleAppraisalActions.RetrieveSuboardinatesWithPhaseStatuses({ getStrucureSubordinates: true }));
                // Recupero la lista di subordinati con lo stato delle fasi
                this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
                  this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
                });
                if (!(this.pasData && this.pasData.perfYear)) {
                  this.getPasInfo();
                }
              }
              if (this.runningPhaseDate && (isSelfAssessment || this.canAccessFeedback)) {
                this.getPasInfoSelf();
              }
            }
            this.loadingAllData = false;
          });
    });
  }

  ngOnInit(): void {
  }

  // Apre il tutorial
  openTutorial() {

    this.translate.get(
      [
        'tutorials.home.1.TITLE',
        'tutorials.home.1.DESC',
        'tutorials.home.2.TITLE',
        'tutorials.home.2.DESC',
        'tutorials.home.3.TITLE',
        'tutorials.home.3.DESC',
        'tutorials.home.4.TITLE',
        'tutorials.home.4.DESC',
        'tutorials.home.5.TITLE',
        'tutorials.home.5.DESC',
        'tutorials.home.6.TITLE',
        'tutorials.home.6.DESC',
        'tutorials.home.7.TITLE',
        'tutorials.home.7.DESC',
        'tutorials.home.8.TITLE',
        'tutorials.home.8.DESC',
        'tutorials.home.9.TITLE',
        'tutorials.home.9.DESC',
        'tutorials.home.10.TITLE',
        'tutorials.home.10.DESC',
        'tutorials.home.11.TITLE',
        'tutorials.home.11.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#home-logo',
          /** Titolo */
          title: translations['tutorials.home.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.1.DESC'],
          orientation: Orientation.Right,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            this.onHomeTutorialClosed();
          }
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
        }, {
          /** Selettore html */
          selector: '#supportTagIcon',
          /** Titolo */
          title: translations['tutorials.home.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.2.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#notifications',
          /** Titolo */
          title: translations['tutorials.home.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.3.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          // Informazioni utente (avatar, nome, ecc.)
          /** Selettore html */
          selector: '#menu-profile',
          /** Titolo */
          title: translations['tutorials.home.10.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.10.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 10
        },
        // }, {
        //   /** Selettore html */
        //   selector: '#bigBannerTag',
        //   /** Titolo */
        //   title: translations['tutorials.home.4.TITLE'],
        //   /** Tour step text */
        //   content: translations['tutorials.home.4.DESC'],
        //   orientation: Orientation.Bottom,
        // }, {
        //   {/** Selettore html */
        //   selector: '#privateBoxTag',
        //   /** Titolo */
        //   title: translations['tutorials.home.5.TITLE'],
        //   /** Tour step text */
        //   content: translations['tutorials.home.5.DESC'],
        //   orientation: Orientation.Top,
        //   highlightPadding: 15
        // }
        {
          /** Selettore html */
          selector: '#content-home',
          /** Titolo */
          title: translations['tutorials.home.11.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.11.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }
        ]

        // // Colloqui
        // if (this.showSeeInterviewBox()) {
        //   this.steps.push({
        //     /** Selettore html */
        //     selector: '#interviewsTag',
        //     /** Titolo */
        //     title: translations['tutorials.home.8.TITLE'],
        //     /** Tour step text */
        //     content: translations['tutorials.home.8.DESC'],
        //     orientation: Orientation.Top,
        //     highlightPadding: 15
        //   })
        // }

        // Dashboard
        // this.steps.push({
        //   /** Selettore html */
        //   selector: '#dashboardBoxTag',
        //   /** Titolo */
        //   title: translations['tutorials.home.6.TITLE'],
        //   /** Tour step text */
        //   content: translations['tutorials.home.6.DESC'],
        //   orientation: Orientation.Top,
        //   highlightPadding: 15
        // });

        const intro = {
          /** Identifier for tour */
          tourId: 'HOME TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            this.onHomeTutorialClosed();
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        setTimeout(() => {
          this.guidedTour.startTour(intro);
        }, 50);
      });
  }

  checkShowTeamUpwardFeedback() {
    if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length) {
      return true;
    }
    return false;
  }

  // Gestisce la chiusura del tutorial della home
  onHomeTutorialClosed() {
    sessionStorage.removeItem('openHomeTutorial');

    const updateOnboardingUserAck = sessionStorage.getItem('updateOnboardingUserAck');


    // Se richiesto, aggiorno le user ack dell'utente (significa che era la prima volta che l'utente entrava nell'applicazione e si è aperto da solo il tutorial)
    if (updateOnboardingUserAck) {

      this.isUpdatingAcks = true;
      if (this.updateUserAck$) {
        this.updateUserAck$.unsubscribe();
      }
      let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
      acks.data['ALLOY_PERFORMANCE_ONBOARDING'] = moment().toISOString();

      this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
        subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "133",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.store.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
            this.isUpdatingAcks = false;
            sessionStorage.removeItem('updateOnboardingUserAck');
          }
        })
      //}
    }
  }

  async getPasInfo() {
    // Controllo se esiste già il subscribe
    if (this.pasInfo$) {
      this.pasInfo$.unsubscribe();
    }
    // Avvio il loader
    this.isLoadingPasInfo = true;

    // Faccio la chiamata per recuperare le info per il badge PAS 
    await this.peopleAppraisalService.getPerformanceAssessmentProcessInfoForManager()
      .subscribe((data: SenecaResponse<GetPerformanceAssessmentInfoForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pasInfo001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPasInfo = false;
        } else {
          // Salvo i dati  
          this.pasData = {
            perfYear: data.response.perfYear,
            perfYearCode: data.response.perfYearCode,
            isNewPas: data.response.isPasNew,
            endDate: moment().isBetween(moment(), data.response.peopleAppraisalEndDate, undefined, '(]') ? moment(data.response.peopleAppraisalEndDate).format("DD.MM.YYYY") : '',
            showFeedback: data.response.showFeedback,
            showFeedback360: data.response.showFeedback360 && this.feedbackRunningRound?.roundStatus === "RUNNING" ? true : false, // mostra o nasconde feedback 360
          }
          this.isLoadingPasInfo = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pasInfo002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPasInfo = false;
      })
  }
  async getPasInfoSelf() {
    // Controllo se esiste già il subscribe
    if (this.pasInfo$) {
      this.pasInfo$.unsubscribe();
    }
    // Avvio il loader
    this.isLoadingPasInfoUser = false;

    // Faccio la chiamata per recuperare le info per il badge PAS 
    await this.peopleAppraisalService.getPerformanceAssessmentProcessInfoForSubordinate()
      .subscribe((data: SenecaResponse<GetPerformanceAssessmentInfoForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pasInfo001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPasInfoUser = false;
        } else {
          // Salvo i dati  
          this.pasDataSelf = {
            perfYear: data.response.perfYear,
            perfYearCode: data.response.perfYearCode,
            isNewPas: data.response.isSelfNew,
            endDate: moment().isBetween(moment(), data.response.selfAssessmentEndDate, undefined, '(]') ? moment(data.response.selfAssessmentEndDate).format("DD.MM.YYYY") : '',
            showFeedback: data.response.showFeedback, // mostra o nasconde smart e peer feedback
            showFeedback360: data.response.showFeedback360 && this.feedbackRunningRound?.roundStatus === "RUNNING" ? true : false, // mostra o nasconde feedback 360
            feedback360NewBadge: data.response.feedback360NewBadge, // Badge novità su richiesta feedback
            showFeedback360Suggestions: data.response.showFeedback360Suggestions // mostra o nasconde feedback 360 suggestion
          }
          this.isLoadingPasInfoUser = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pasInfo002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPasInfoUser = false;
      })
  }



  // Recupera informazioni Upward Feedback e la lista dei manager se è un valutatore
  getUpwardFeedbackInfoForManager() {
    this.isLoadingUpwardFeedback = true;
    this.peopleAppraisalService.getUpwardFeedbackInfo()
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.upwardFeedbackData = data.response;
          // this.upwardFeedbackData.secondLevelEvaluation = [{ user: null, resultAvailable: false, alreadyCommented: false }];
          if (this.upwardFeedbackData?.secondLevelEvaluation?.subordinates) {
            this.upwardFeedbackManagers = [];
            for (let i = 0; i < this.upwardFeedbackData.secondLevelEvaluation.subordinates.length; i++) {
              let item = this.upwardFeedbackData.secondLevelEvaluation.subordinates[i];
              this.upwardFeedbackManagers.push({
                ...item.user,
                name: item.user.forename,
                cardStatus: item.alreadyCommented ? 'COMPLETED' : 'TO_HELP',
                resultAvailable: item.resultAvailable
              });
            }
            this.upwardFeedbackManagers = this.upwardFeedbackManagers.filter((user: any) => user.resultAvailable)
          }
        }
        this.isLoadingUpwardFeedback = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpwardFeedback = false;
      })
  }

  // Controllo se ho la tile di Upward Feedback
  hasUpwardFeedback() {
    return (!this.isLoadingUpwardFeedback && this.upwardFeedbackData && moment().isSameOrAfter(this.upwardFeedbackData.startDate) && moment().isSameOrBefore(this.upwardFeedbackData.endDate) && this.upwardFeedbackManagers && this.upwardFeedbackManagers.length > 0);
  }
  // Controllo se ho la tile di Upward Feedback self
  hasUpwardFeedbackSelf() {
    return (!this.isLoadingUpwardFeedback && this.upwardFeedbackDataSelf && moment().isSameOrAfter(this.upwardFeedbackDataSelf.startDate) && moment().isSameOrBefore(this.upwardFeedbackDataSelf.endDate) && this.upwardFeedbackManagers && this.upwardFeedbackManagers.length > 0);
  }

  //Recupera informazioni Upward Feedback e la lista dei manager se è un valutatore
  getUpwardFeedbackInfoForUser() {
    this.isLoadingUpwardFeedback = true;
    this.selfAssessmentService.getUpwardFeedbackInfo()
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpwardFeedback = false;
        } else {
          this.upwardFeedbackManagers = [];
          if (data.response.managersToEvaluate) {
            for (let i = 0; i < data.response.managersToEvaluate.length; i++) {
              let item = data.response.managersToEvaluate[i];
              this.upwardFeedbackManagers.push({
                ...item.user,
                name: item.user.forename,
                cardStatus: item.evaluationStatus
              });
            }
          }
          this.upwardFeedbackDataSelf = data.response;
        }
        this.isLoadingUpwardFeedback = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpwardFeedback = false;
      })
  }

  // //Recupera informazioni Upward Feedback e la lista dei manager se è un valutatore
  // getUpwardFeedbackInfoSelf() {
  //   this.isLoadingUpwardFeedback = true;
  //   this.selfAssessmentService.getUpwardFeedbackInfo()
  //     .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForUser>) => {
  //       if (data && data.error) {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "upf001",
  //           text: this.translate.instant("errors." + data.error),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //         this.isLoadingUpwardFeedback = false;
  //       } else {
  //         this.upwardFeedbackDataSelf = data.response;
  //       }
  //       this.isLoadingUpwardFeedback = false;
  //     }, (err: any) => {
  //       const messageObj: ApplicationModalMessage = {
  //         modalId: "upf002",
  //         text: this.translate.instant("errors." + ((err && err.message) || err)),
  //         title: this.translate.instant("generic.WARNING")
  //       }
  //       this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //       this.isLoadingUpwardFeedback = false;
  //     })
  // }

  openUpwardFeedback(isSelf?: boolean) {
    if (isSelf && (this.upwardFeedbackData && this.upwardFeedbackData.selfEvaluation)) {
      this.redirectService.goToUpwardFeedbackPeopleAppraisal(this.upwardFeedbackData?.roundId)
    } else if (!isSelf && this.upwardFeedbackData && this.upwardFeedbackData.secondLevelEvaluation) {
      this.openUpwardFeedbackModal();
    }
  }

  goToPersonDetails(isSelf?: boolean) {
    if (this.loggedUser.userId && this.runningYear.year && this.runningYear.yearCode) {
      if (isSelf) {
        this.router.navigate(['/selfAssessment/personDetails/' + this.loggedUser.userId + '/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
      } else {
        this.router.navigate(['/peopleAppraisal/personDetails/' + this.subordinatesWithPhaseStatuses[0]?.userId + '/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
      }
    }
  }
  openUpwardFeedbackSelf() {
    this.selectedFeedbackManager = null;
    if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length) {
      for (let i = 0; i < this.upwardFeedbackManagers.length; i++) {
        this.upwardFeedbackManagers[i].isChecked = false;
      }
    }

    if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length == 1 && this.upwardFeedbackManagers[0].cardStatus != 'CLOSED') {
      this.selectedFeedbackManager = this.upwardFeedbackManagers[0];
      this.goToSendFeedback();
    } else if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length == 1 && this.upwardFeedbackManagers[0].cardStatus == 'CLOSED') {
      const messageObj: ApplicationModalMessage = {
        modalId: "upferr001",
        title: this.translate.instant("errors.DENIED_NOTES_TITLE"),
        text: this.translate.instant("upwardFeedback.errors.SELF_ASS_DESCR")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    } else {
      this.modalService.open('upward-feedback');
    }
  }

  goToSendFeedback() {
    // Assegno l'unica persona selezionata
    if (this.selectedFeedbackManager && this.upwardFeedbackDataSelf) {
      this.redirectService.goToUpwardFeedbackSelfAssessment(this.upwardFeedbackDataSelf.roundId, this.selectedFeedbackManager.userId);
    }
  }

  goToPeopleAppraisal360Feedback() {
    this.router.navigate(['/peopleAppraisal/teamFeedback360']);
  }

  goToDashboard() {
    this.router.navigate(['/peopleAppraisal/dashboard/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
  }

  goToPrivateNotes() {
    this.router.navigate(['/selfAssessment/personDetails/myNotes/' + this.loggedUser.userId + '/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
  }
  goToYourFeedbacks() {
    this.router.navigate(['/selfAssessment/personDetails/feedback/' + this.loggedUser.userId + '/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
  }
  goToTakeNote() {
    this.router.navigate(['/peopleAppraisal/createUpdatePrivateNote']);
  }

  openCollaboratorsCppCompletedModal() {
    this.modalService.open("completed-collaborators-cpp-modal");
  }

  // Vai al cpp dell'utente
  goToUserCPP() {
    if (this.cppInfoForUser.progress == 100) {
      this.openCompletionCppModal();
    } else {
      this.redirectService.goToUserCpp(this.cppInfo?.roundId);
    }
  }

  openCompletionCppModal() {
    this.modalService.open("completion-cpp-modal");
  }

  closeCompletionCppModal(downloadReport?: boolean) {
    if (downloadReport) {
      this.downloadCppReport();
    } else {
      this.modalService.close("completion-cpp-modal");
    }
  }

  closeCollaboratorsCppCompletedModal(downloadReport?: boolean, notRefreshSelectedList?: boolean) {
    if (downloadReport) {
      this.downloadCppReport();
    } else {
      if (!notRefreshSelectedList) {
        this.selectedUserList = [];
        if (this.cppInfo.completedCollaboratorsData?.length) {
          this.cppInfo.completedCollaboratorsData.forEach((element: any) => {
            element.isChecked = false;
          });
        }
        if (this.completedCollaboratorsList?.length) {
          this.completedCollaboratorsList.forEach((element: any) => {
            element.isChecked = false;
          });
        }
      }
      this.modalService.close("completion-cpp-modal");
    }
  }


  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  getCppInfoForUser() {
    this.isLoadingCppInfo = true;

    this.selfAssessmentService.getCppInfoForUser()
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppInfoForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta0012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cppInfoForUser = data.response;
          if (this.cppInfoForUser?.startDate && this.cppInfoForUser?.endDate) {
            this.isBetweenDatesCppForUser = moment(this.today).isBetween(moment(this.cppInfoForUser.startDate), moment(this.cppInfoForUser.endDate));
          } else {
            this.isBetweenDatesCppForUser = true;
          }
        }
        this.isLoadingCppInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ta00022",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppInfo = false;
      });
  }

  downloadCppReport() {
    this.isDownloadingCppReport = true;

    this.closeCollaboratorsCppCompletedModal(false, true);

    let userIds = this.selectedUserList.map((x: any) => {
      return x.id;
    })

    this.peopleAppraisalService.downloadCppReport(this.cppInfo?.roundId, userIds)
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingCppReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.selectedUserList = [];
              if (this.cppInfo.completedCollaboratorsData?.length) {
                this.cppInfo.completedCollaboratorsData.forEach((element: any) => {
                  element.isChecked = false;
                });
              }
              if (this.completedCollaboratorsList?.length) {
                this.completedCollaboratorsList.forEach((element: any) => {
                  element.isChecked = false;
                });
              }
              this.isDownloadingCppReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingCppReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingCppReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingCppReport = false;
      })
  }

  searchUsers() {
    this.selectedUserList = [];
    if (this.cppInfo.completedCollaboratorsData?.length) {
      this.cppInfo.completedCollaboratorsData.forEach((element: any) => {
        element.isChecked = false;
      });
    }
    if (this.completedCollaboratorsList?.length) {
      this.completedCollaboratorsList.forEach((element: any) => {
        element.isChecked = false;
      });
    }
    this.completedCollaboratorsList = this.cppInfo.completedCollaboratorsData.filter((x: any) => {
      let completeName = x.name + ' ' + x.surname;
      return completeName.toLowerCase().includes(this.searchUserText);
    })
  }

  openUpwardFeedbackModal() {
    this.selectedFeedbackManager = null;
    if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length) {
      for (let i = 0; i < this.upwardFeedbackManagers.length; i++) {
        this.upwardFeedbackManagers[i].isChecked = false;
      }
    }
    this.modalService.open('upward-feedback');
  }

  changeSearchedTextValue(text: string) {
    this.searchUserText = text;
  }
  getCppInfoForManager() {
    this.isLoadingCppInfo = true;

    this.peopleAppraisalService.getCppInfoForManager()
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppInfoForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta0000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cppInfo = data.response;
          if (this.cppInfo?.startDate && this.cppInfo?.endDate) {
            this.isBetweenDatesCpp = moment(this.today).isBetween(moment(this.cppInfo.startDate), moment(this.cppInfo.endDate));
          } else {
            this.isBetweenDatesCpp = true;
          }
          if (this.cppInfo?.completedCollaboratorsData?.length) {
            this.cppInfo.completedCollaboratorsData = UserDataUtils.getUserCardData(this.cppInfo.completedCollaboratorsData) || [];
            this.completedCollaboratorsList = JSON.parse(JSON.stringify(this.cppInfo.completedCollaboratorsData));
          }
        }
        this.isLoadingCppInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ta00001",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppInfo = false;
      });
  }

  // Seleziono l'utente per il Upward Feedback
  chooseUpwardFeedbackUser(newUser: any) {
    this.upwardFeedbackManagers.forEach((user: any) => user.isChecked = false);
    newUser.isChecked = true;
    this.selectedFeedbackManager = newUser;
  }

  goToCheckFeedbackResults() {
    if (this.upwardFeedbackData) {
      this.redirectService.goToUpwardFeedbackPeopleAppraisal(this.upwardFeedbackData?.roundId, this.selectedFeedbackManager.userId)
    }
  }

  closeUpwardFeedbackModal() {
    this.modalService.close('upward-feedback');
  }

  isUpwardFeedbackDateValid() {
    if (!this.isLoadingUpwardFeedback && this.upwardFeedbackData) {
      return moment().isSameOrAfter(this.upwardFeedbackData.startDate) && moment().isSameOrBefore(this.upwardFeedbackData.endDate)
    }
    return false;
  }

  checkShowSelfUpwardFeedback() {
    if (this.upwardFeedbackData && this.upwardFeedbackData.selfEvaluation) {
      return this.upwardFeedbackData.selfEvaluation.resultAvailable;
    }
    return false
  }

  // Recupero l'immagine del banner
  getBannerImage(phaseName: string) {
    if (this.isPeopleAppraisal) {
      return 'assets/img/head/sol-banner.png';
    }
    return 'assets/img/head/sol-banner.png';
  }

  goToSmartFeedback() {
    this.router.navigate(['/selfAssessment/sendApplause']);
  }

  goToPeerFeedback() {
    this.router.navigate(['/selfAssessment/createUpdatePeerFeedback']);
  }

  goTo360Feedback(received: boolean) {
    if (received) {
      this.router.navigate(['/selfAssessment/receive360Feedback/']);
    } else {
      this.router.navigate(['/selfAssessment/requested360Feedback']);
    }
  }

  ngAfterViewInit() {
    if (!this.isImpersonate) {
      const forceOpenTutorial = sessionStorage.getItem('openHomeTutorial');

      if (forceOpenTutorial) {
        this.openTutorial();
      }
    }
  }

  ngOnDestroy() {
    const forceOpenTutorial = sessionStorage.getItem('openHomeTutorial');

    if (forceOpenTutorial) {
      sessionStorage.removeItem('openHomeTutorial');
    }
    if (this.feedbackSubordinates$) {
      this.feedbackSubordinates$.unsubscribe();
    }
    if (this.feedbackRunningRound$) {
      this.feedbackRunningRound$.unsubscribe();
    }
  }
}
