<div class="header-container">
    <!-- Contenitore Titolo e Icona -->
    <div class="header">
        <div class="content-container">
            <ng-container>
                <!-- Icona -->
                <svg-icon [src]="icon" class="small-size" [applyClass]="true"></svg-icon>
                <!-- Titolo  -->
                <p *ngIf="title" [innerHTML]="title"></p>
            </ng-container>
        </div>
        <div class="right-section-container">
            <item-list-team-status *ngIf="type" styleItem="fill" [type]="type" [isFeedbackStatus]="true"></item-list-team-status>
            <!-- Icona tempo -->
            <div class="period-time">
                <p *ngIf="creationDate">{{creationDate}}</p>
                <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
            </div>
        </div>
    </div>
    <!-- Avatar -->
    <ng-container *ngIf="hasAvatar">
        <div class="avatar-container" [ngClass]="{'left': !avatarRight}">
            <!-- Avatar destra -->
            <ng-container *ngIf="avatarRight">
                <div class="right-avatar">
                    <svg-icon [src]="'assets/img/icons/rectangle-right.svg'"></svg-icon>
                    <avatar-img [user]="user" size="medium"></avatar-img>
                </div>
            </ng-container>
            <!-- Avatar sinistra -->
            <ng-container *ngIf="!avatarRight">
                <div class="left-avatar">
                    <avatar-img [user]="user" size="medium"></avatar-img>
                    <svg-icon [src]="'assets/img/icons/rectangle-left.svg'"></svg-icon>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>