import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PerfAlloyCompetence } from 'atfcore-commonclasses';


@Component({
  selector: 'competence-evaluation',
  templateUrl: 'competence-evaluation.component.html',
  styleUrls: ['./competence-evaluation.component.scss']
})
export class CompetenceEvaluationComponent implements OnInit {
  @Input() isLeadershipRating: boolean = false;
  @Input() competenceList: any;
  @Input() id?: any;
  @Input() titlesRightSection: any;
  @Input() ratingScale: number = 5;
  @Input() disabled: boolean = false;
  @Input() titleRightSection?: { textLeft: string, textRight: string };
  @Input() showInfo: boolean = true;

  @Input() isNotApplicable: boolean = false;
  @Input() hideComments: boolean = false;
  @Input() hideFeedbacks: boolean = false;
  // @Input() hideSkillMatrix: boolean = false;
  @Input() canValueNA?: boolean;
  @Input() noContainer: boolean = false;
  // Con la doppia valutazione decide quale label usare per le colonne
  @Input() isManager: boolean = false;
  @Input() isPostCalibration: boolean = false;

  // Indica se il people appraisal può modificare la valutazione
  @Input() canEditInCompare: boolean = false;
  @Input() isFeedback360: boolean = false;

  @Output() ratingChanged = new EventEmitter();
  @Output() infoClicked = new EventEmitter();
  @Output() onPlusMinusClicked = new EventEmitter();
  @Output("onRating") onRatingEvent = new EventEmitter();
  @Output("onCommentInsertion") onCommentInsertionEvent = new EventEmitter();
  @Output("onCommentList") onCommentListEvent = new EventEmitter();
  @Output("onFeedbackList") onFeedbackListEvent = new EventEmitter();
  @Output("onSkillMatrixDownload") onSkillMatrixDownloadEvent = new EventEmitter();



  ngOnInit() {
  }

  onRatingChanged(rating: any) {
    this.ratingChanged.emit(rating);
  }

  onInfoClicked(competence: any, category: string) {
    competence.category = category;
    this.infoClicked.emit(competence);
  }

  emitOnPlusMinusClicked(tag: any, isPlus?: boolean) {
    tag.plusChecked = !!isPlus;
    this.onPlusMinusClicked.emit(tag);
  }

  /**
   * Index della competenza nella quale inserire il commento
   * @param index 
   */
  insertCommentEvent(index: number) {
    this.onCommentInsertionEvent.emit(index)
  }

  onCommentList(commentExist: boolean, comments?: any[]) {
    if (commentExist) {
      this.onCommentListEvent.emit(comments)
    }
  }
  onFeedbackList(tag: PerfAlloyCompetence) {
    if (tag.feedbacks?.length) {
      this.onFeedbackListEvent.emit(tag)
    }
  }

  onSkillMatrixDownload() {
    this.onSkillMatrixDownloadEvent.emit()
  }

  onRating(ratingObj: any) {
    this.onRatingEvent.emit({
      ...ratingObj.tag,
      evaluationScore: ratingObj.rating
    })
  }

  checkEvaluationAvailable() {
    if (this.competenceList?.competences?.length > 0) {
      for (let i = 0; i < this.competenceList.competences.length; i++) {
        let tag = this.competenceList.competences[i];
        if (tag.colleagueEvaluationRating && tag.colleagueEvaluationRating) {
          return true;
        }
      }
    }
    return false;
  }
}