import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import moment from 'moment';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import { PerfAlloyFeedbackTypes } from 'atfcore-commonclasses';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'card-feedback',
  templateUrl: 'card-feedback.component.html',
  styleUrls: ['./card-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFeedbackComponent implements OnInit {
  @Input() object: any;
  @Input() placeholder: string = 'Inserisci una descrizione';
  @Input() bindValue: string = '';
  @Input() isReceiving: boolean = false;
  @Input() loggedUser: any;
  @Input() readonly: boolean = false;
  @Input() isClap: boolean = false;
  @Input() isHistory: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onEditObject: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteObject: EventEmitter<any> = new EventEmitter();

  item: {
    type: PerfAlloyFeedbackTypes | 'PERFALLOY_PRIVATE_NOTES',
    icon: string,
    title: string
  } = {
      type: '',
      icon: '',
      title: ''
    }


  constructor(private store: Store<fromApp.AppState>,
    private translate: TranslateService) {
    this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      moment.locale(applicationLang);
    })
  }

  ngOnInit() {
    this.object.creationDateAgo = moment(this.object.creationDate).fromNow();

    // Controllo di che card si tratta (note private | smart feedback | peer feedback)
    if (window.location.href.indexOf('myNotes') > -1) {
      // Assegno i valori all'item in caso io mi trovi nelle mie note
      this.item = {
        type: 'PERFALLOY_PRIVATE_NOTES',
        icon: 'assets/img/icons/clipboard.svg',
        title: this.translate.instant('feedbackSection.privateNotes.PRIVATE_NOTE')
      }
    } else if (window.location.href.indexOf('feedback') > -1) {
      // Assegno i valori all'item in caso di smart feedback
      if (this.object.feedbackType == PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK) {
        this.item = {
          type: PerfAlloyFeedbackTypes.PERFALLOY_SMART_FEEDBACK,
          icon: 'assets/img/icons/clapping-hands.svg',
          title: this.translate.instant('feedbackSection.smartFeedback.SENDED_SMART_FEEDBACK' + ' ' + this.object.recipientUser.forename + ' ' + this.object.recipientUser.surname + '</b>')
        }
      } else {
        // Assegno i valori all'item in caso di peer feedback
        this.item = {
          type: PerfAlloyFeedbackTypes.PERFALLOY_PEER_FEEDBACK,
          icon: 'assets/img/icons/hard-hat.svg',
          title: this.translate.instant('feedbackSection.smartFeedback.RECEIVED_SMART_FEEDBACK' + ' ' + this.object.senderUser.forename + ' ' + this.object.senderUser.surname + '</b>')
        }
      }
    }
  }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  editObject(id: string) {
    this.onEditObject.emit(id);
  }

  deleteObject(id: string) {
    this.onDeleteObject.emit(id);
  }
}