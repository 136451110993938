<div class="competence-evaluation-container" id="{{ id + '-container' }}">
  <ng-container *ngIf="competenceAvg?.category">
    <p class="competence-category">{{ competenceAvg?.category || '' }}</p>
  </ng-container>

  <ng-container>
    <div class="competence-data-container" id="{{ 'competenceAvgIndex' + id + '-container' }}">
      <!-- Lato sinistro con titolo e descrizione -->
      <div class="data-content">
        <ng-container *ngIf="competenceAvg?.title">
          <div class="title-container">
            <p class="title">{{ competenceAvg?.title }}</p>
          </div>
        </ng-container>
        <p class="description">{{competenceAvg?.subtitle}}</p>
      </div>
      <div class="divisor-vertical"></div>

      <!-- Rating competenza -->
      <ng-container *ngIf="!showSelfRating()">
        <div class="evaluation-rating-stars">
          <competence-evaluation-system [direction]="'column'" [size]="'big'" [readOnly]="true" [items]="5"
            [level]="competenceAvg.ratingStarsRounded ? competenceAvg.ratingStarsRounded : competenceAvg.ratingStars"
            [isNotApplicable]="false" [competenceScale]="true" [showTitles]="true" [showDescr]="false"
            [titleStyleAsDescription]="true"></competence-evaluation-system>
          <p class="number">{{ competenceAvg.ratingStars | number:'1.2-2' }}</p>
        </div>
      </ng-container>

      <!-- Rating competenza (con recap)-->
      <ng-container *ngIf="showSelfRating()">

        <div class="rating-star-content">
          <!--star-rating [forceRating]="competenceAvg?.selfRatingStars" customContainerClass="small-star-size"
            [grayColor]="true" [scale]="ratingScale" [disabled]="true" [showFullNumber]="true"
            [fullNumber]="competenceAvg?.selfRatingAvgString">
          </star-rating-->

          <!-- Recap valutazione manager -->
          <div class="evaluation-rating-stars small">
            <competence-evaluation-system [direction]="'column'" [size]="'small'" [readOnly]="true" [items]="5"
              [level]="competenceAvg.ratingStarsRounded ? competenceAvg.ratingStarsRounded : competenceAvg.ratingStars"
              [isNotApplicable]="false" [competenceScale]="true" [showTitles]="true" [showDescr]="false"
              [titleStyleAsDescription]="true"></competence-evaluation-system>
            <p class="number">{{ competenceAvg.ratingStars | number:'1.2-2' }}</p>
          </div>
          <div class="divisor-vertical"></div>
          <!-- Recap valutazione subordinato -->
          <div class="evaluation-rating-stars small">
            <competence-evaluation-system [direction]="'column'" [size]="'small'" [readOnly]="true" [items]="5"
              [level]="competenceAvg.colleagueRatingStarsRounded ? competenceAvg.colleagueRatingStarsRounded : competenceAvg.colleagueRatingStars"
              [isNotApplicable]="false" [competenceScale]="true" [showTitles]="true" [showDescr]="false"
              [titleStyleAsDescription]="true" [secondaryColor]="true"></competence-evaluation-system>
            <p class="number">{{ competenceAvg.colleagueRatingStars | number:'1.2-2' }}</p>
          </div>
        </div>
      </ng-container>
    </div>

  </ng-container>
</div>